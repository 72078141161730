import React from "react";
import { ReactComponent as LogoWhite } from "./RISE_WHITE.svg";
import { ReactComponent as LogoBlack } from "./RISE_BLACK.svg";
import { SizeOptions } from "../../theme";

type Props = {
  color?: "white" | "black";
  size?: SizeOptions;
};

const SIZES = {
  sm: [14, 18],
  xs: [24, 32],
  md: [32, 42],
  lg: [44, 56],
  xl: [46, 58],
};

const RiseLogo: React.FC<Props> = ({ color = "white", size }) => {
  const props = {
    width: SIZES[size ?? "md"][0],
    height: SIZES[size ?? "md"][1],
    viewBox: "0 0 502 643",
  };
  const Comp =
    color === "white" ? <LogoWhite {...props} /> : <LogoBlack {...props} />;
  return Comp;
};

export default RiseLogo;
