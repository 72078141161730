import React from "react";
import styled from "styled-components/macro";
import * as RadixDialog from "@radix-ui/react-dialog";
import Icon from "./Icon";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import HeaderComponent from "../../common/text/Header";

type Props = {
  trigger?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  logo?: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const Dialog: React.FC<Props> = ({
  trigger,
  children,
  title,
  logo,
  defaultOpen,
  onOpenChange,
  open,
}) => {
  return (
    <DialogRoot
      open={open}
      defaultOpen={defaultOpen}
      modal
      onOpenChange={onOpenChange}
    >
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent>
            {logo && <LogoContainer>{logo}</LogoContainer>}
            {title && <DialogTitle type="h2">{title}</DialogTitle>}
            {children}
            <DialogClose>
              <Icon icon={faClose} size="lg" />
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </DialogRoot>
  );
};

const DialogRoot = styled(RadixDialog.Root)``;
const DialogTrigger = styled(RadixDialog.Trigger)``;
const DialogPortal = styled(RadixDialog.Portal)``;
const DialogOverlay = styled(RadixDialog.Overlay)`
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: rgba(34, 34, 34, 0.3);
  z-index: ${({ theme }) => theme.zIndex.dialog};
`;

const DialogContent = styled(RadixDialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 85vh;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.white};
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: auto;
`;

const DialogTitle = styled(HeaderComponent)`
  padding-bottom: ${({ theme }) => theme.spacing.lg};
`;

const DialogClose = styled(RadixDialog.Close)`
  position: fixed;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border: none;
  cursor: pointer;

  :hover {
    color: rgba(0, 0, 0, 1);
  }
`;

const LogoContainer = styled.div`
  display: block;
  padding-bottom: ${({ theme }) => theme.spacing.md};
`;

export default Dialog;
