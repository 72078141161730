import React from "react";
import styled from "styled-components/macro";
import theme from "../../theme";

export type ButtonProps = {
  variant: keyof (typeof theme)["buttons"];
  type?: "submit" | "reset" | "button" | undefined;
  bold?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
};

const Button = styled.button<ButtonProps>`
  background-color: ${({ theme, variant }) =>
    theme.buttons[variant].backgroundColor};
  border: ${({ theme, variant }) => theme.buttons[variant].border};
  box-shadow: ${({ theme, variant }) => theme.buttons[variant].boxShadow};
  color: ${({ theme, variant }) => theme.buttons[variant].text.color};
  font-family: ${({ theme, variant }) =>
    theme.buttons[variant].text.fontFamily};
  font-size: ${({ theme, variant }) => theme.buttons[variant].text.fontSize};
  line-height: ${({ theme, variant }) =>
    theme.buttons[variant].text.lineHeight};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.default}`};
  cursor: pointer;

  :hover,
  :focus,
  :active {
    background-color: ${({ theme, variant }) =>
      theme.buttons[variant].backgroundColorHover};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.greyLight};
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export default Button;
