import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Flex } from "../containers";
import styled from "styled-components/macro";
import { SizeOptions } from "../../theme";

type Props = {
  icon: FontAwesomeIconProps["icon"];
  size?: SizeOptions;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  "aria-label"?: string;
  disabled?: boolean;
};

const Icon = React.forwardRef<any, Props>(
  ({ label, onClick, ...props }, forwardedRef) => {
    let usedProps = !onClick ? { ...props } : {};
    let component = (
      <StyledFlex
        {...usedProps}
        ref={!onClick ? forwardedRef : undefined}
        align="center"
        gap="xs"
      >
        <FontAwesomeIcon {...props} />
        {label && <Label size={props.size}>{label}</Label>}
      </StyledFlex>
    );

    if (!!onClick)
      return (
        <UnstyledButton
          disabled={props.disabled}
          onClick={onClick}
          {...props}
          ref={forwardedRef}
        >
          {component}
        </UnstyledButton>
      );

    return component;
  }
);

// TODO: här hemma i temat?
const FONT_SIZES = {
  xs: "0.60em",
  sm: "0.75em",
  md: "0.85em",
  lg: "1.0em",
  xl: "1.25em",
};

const UnstyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: ${({ theme }) => theme.typography.fontPrimary};
  padding: 0;
  cursor: pointer;

  :focus {
    border: none;
    box-shadow: none;
  }
`;

const StyledFlex = styled(Flex)``;
const Label = styled.span<{
  size?: SizeOptions;
}>`
  font-size: ${({ size }) => FONT_SIZES[size ?? "md"]};
`;

export default Icon;
