import React, { memo } from "react";
import styled from "styled-components/macro";
import { Property } from "csstype";
import theme from "../../theme";

type BaseProps = {
  align?: Property.AlignItems;
  justify?: Property.JustifyContent;
  gap?: keyof typeof theme["spacing"];
  column?: boolean;
  wrap?: boolean;
  onClick?: () => void;
  reverse?: boolean;
};

type Props = BaseProps & {
  children?: React.ReactNode;
};

const Flex = React.forwardRef<HTMLDivElement, Props>(
  ({ children, wrap, gap, ...props }, forwardedRef) => {
    return (
      <StyledFlex $gap={gap} $wrap={wrap} ref={forwardedRef} {...props}>
        {children}
      </StyledFlex>
    );
  }
);

const StyledFlex = styled.div<
  Omit<BaseProps, "wrap" | "gap"> & { $wrap?: boolean; $gap?: BaseProps["gap"] }
>`
  display: flex;
  flex-direction: ${({ column, reverse }) =>
    column
      ? reverse
        ? "column-reverse"
        : "column"
      : reverse
      ? "row-reverse"
      : "row"};
  align-items: ${({ align }) => align ?? ""};
  justify-content: ${({ justify }) => justify ?? ""};
  gap: ${({ $gap, theme }) => ($gap ? theme.spacing[$gap] : "")};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "")};
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "")};
`;

export default memo(Flex);
