import React from "react"
import styled from "styled-components/macro"
import * as RadixTooltip from "@radix-ui/react-tooltip"

type Props = {
  children: React.ReactNode
  tooltip: string
}

const Tooltip: React.FC<Props> = ({ children, tooltip }) => {
  return (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent sideOffset={5}>
            {tooltip}
            <TooltipArrow />
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  )
}

const TooltipProvider = styled(RadixTooltip.Provider)``
const TooltipRoot = styled(RadixTooltip.Root)``
const TooltipTrigger = styled(RadixTooltip.Trigger)`
  all: unset;
`
const TooltipPortal = styled(RadixTooltip.Portal)``
const TooltipContent = styled(RadixTooltip.Content)`
  border-radius: ${({ theme }) => theme.spacing.xxs};
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.typography.fontSizeSm};
  line-height: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
`

const TooltipArrow = styled(RadixTooltip.Arrow)`
  fill: ${({ theme }) => theme.colors.white};
`

export default Tooltip
