import { DefaultTheme, TextType } from "styled-components";

export type SizeOptions = "xs" | "sm" | "lg" | "xl";

// TODO: remove when Material-UI is completely gone, for backchecking purposes
// const theme: DefaultTheme = {
//   colors: {
//     navbarBg: "#222222",
//     text: {
//       bright: "#FFF",
//     },
//     primary: "#019CA6",
//     primaryDark: "#107E88",
//     error: "#E83D63",
//     errorDark: "#C30B34",
//     white: "#fff",
//     black: "#000",
//     shaded: "#F0F0F0",
//     shadedBorder: "#DADADA",
//     action: "#FFF",
//     actionDark: "#F0F0F0",
//   },
//   fontFamilies: {
//     headerText: "Code pro",
//     actionButton: "Helvetica",
//     buttonText: "Code pro",
//     buttonTextHover: "Code pro bold",
//   },
//   spacing: {
//     xs: "0.5rem", // 8px
//     sm: "0.75rem", // 12px
//     default: "1rem", // 16ox
//     md: "1.5rem", // 24px
//     lg: "2rem", // 32px
//     xl: "3rem", // 48px
//   },
// };

const palette = {
  primary: "#019CA6",
  primaryDark: "#107E88",
  complementary: "#482D55",
  error: "#E83C63",
  errorDark: "#C30B34",
  black: "#222",
  white: "#fff",
  grey: "#828282",
  greyLightest: "#F6F6F6",
  greyLight: "#c8c8c8",
  greyDark: "#555",
  borderGrey: "#c8c8c8",
  orange: "#ff7000",
};

const typography = {
  fontPrimary: "Lato",
  fontHeading: "CodePro-Bold",
  fontWeightBold: "700",
  fontWeightNormal: "400",
  fontSizeXs: "0.75rem", // 12px
  fontSizeSm: "0.875rem", // 14px
  fontSizeDefault: "1rem", // 16px
  fontSizeM: "1.125rem", //18px
  fontSizeL: "1.5rem", //24px
  lineHeightDefault: "1.5",
};

const buttonText: TextType = {
  color: palette.white,
  colorContrast: palette.black,
  fontFamily: typography.fontHeading,
  fontSize: typography.fontSizeDefault,
  fontWeight: typography.fontWeightBold,
  lineHeight: "1",
};

const buttons = {
  color: palette.white,
  border: "unset",
  boxShadow: `inset 0 -2px 0 0 ${palette.primaryDark}`,
};

const text = {
  boldFontWeight: typography.fontWeightBold,
  fontFamily: typography.fontPrimary, //Could be removed in the future, as we set default font family on html in index.css
  fontSize: typography.fontSizeDefault,
  lineHeight: typography.lineHeightDefault,
};

const theme: DefaultTheme = {
  colors: {
    primary: palette.primary,
    primaryDark: palette.primaryDark,
    complementary: palette.complementary,
    error: palette.error,
    errorDark: palette.errorDark,
    warning: palette.orange,
    black: palette.black,
    white: palette.white,
    navbarBg: palette.black,
    grey: palette.grey,
    greyDark: palette.greyDark,
    greyLight: palette.greyLight,
    greyLightest: palette.greyLightest,
    dividers: palette.greyLight,
    borderGrey: palette.greyLight,
  },
  typography: {
    fontPrimary: typography.fontPrimary,
    fontHeading: typography.fontHeading,
    fontWeightBold: typography.fontWeightBold,
    fontWeightNormal: typography.fontWeightNormal,
    fontSizeXs: typography.fontSizeXs,
    fontSizeSm: typography.fontSizeSm,
    fontSizeDefault: typography.fontSizeDefault,
    fontSizeM: typography.fontSizeM,
    fontSizeL: typography.fontSizeL,
    lineHeightDefault: typography.lineHeightDefault,
  },
  text: {
    primary: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
    },
    error: {
      ...text,
      color: palette.error,
      colorContrast: palette.errorDark,
    },
    chip: {
      color: palette.black,
      colorContrast: palette.white,
      fontWeight: typography.fontWeightBold,
      backgroundColor: palette.white,
      fontFamily: typography.fontPrimary,
      fontSize: typography.fontSizeXs,
      lineHeight: "1",
      border: `1px solid ${palette.borderGrey}`,
    },
    tabHeaders: {
      ...text,
      color: palette.grey,
      colorContrast: palette.black,
      fontSize: typography.fontSizeSm,
    },
    label: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
    },
    input: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
      colorPlaceholder: palette.grey,
      border: `1px solid ${palette.borderGrey}`,
    },
    explanation: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
      fontSize: typography.fontSizeSm,
    },
  },
  headers: {
    h1: {
      ...text,
      color: palette.white,
      colorContrast: palette.black,
      fontFamily: typography.fontHeading,
      fontSize: typography.fontSizeM,
      lineHeight: "1",
      fontWeight: typography.fontWeightNormal,
    },
    h2: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
      fontFamily: typography.fontHeading,
      fontSize: typography.fontSizeM,
      lineHeight: "1",
      fontWeight: typography.fontWeightNormal,
    },
    h3: {
      ...text,
      color: palette.black,
      colorContrast: palette.white,
      fontFamily: typography.fontHeading,
      fontSize: typography.fontSizeDefault,
      fontWeight: typography.fontWeightNormal,
    },
  },
  buttons: {
    primary: {
      ...buttons,
      backgroundColor: palette.primary,
      backgroundColorHover: palette.primaryDark,
      text: {
        ...buttonText,
      },
    },
    cancel: {
      ...buttons,
      backgroundColor: palette.error,
      backgroundColorHover: palette.errorDark,
      boxShadow: `inset 0 -2px 0 0 ${palette.errorDark}`,
      text: {
        ...buttonText,
      },
    },
    action: {
      border: `1px solid ${palette.borderGrey}`,
      boxShadow: "none",
      backgroundColor: "transparent",
      backgroundColorHover: palette.greyLightest,
      color: palette.black,
      text: {
        ...buttonText,
        color: palette.black,
        colorContrast: palette.white,
        fontFamily: typography.fontPrimary,
        fontSize: typography.fontSizeXs,
      },
    },
    /* TODO: Better way? */
    actionInverted: {
      border: `1px solid ${palette.borderGrey}`,
      boxShadow: "none",
      backgroundColor: "transparent",
      backgroundColorHover: palette.greyDark,
      color: palette.white,
      text: {
        ...buttonText,
        color: palette.white,
        colorContrast: palette.black,
        fontFamily: typography.fontPrimary,
        fontSize: typography.fontSizeXs,
      },
    },
    text: {
      border: "unset",
      boxShadow: "none",
      backgroundColor: "transparent",
      backgroundColorHover: "transparent",
      color: palette.black,
      text: {
        ...text,
        color: palette.black,
        colorContrast: palette.white,
        fontSize: typography.fontSizeSm,
      },
    },
  },
  spacing: {
    xxs: "0.25rem", // 4px
    xs: "0.5rem", // 8px
    sm: "0.75rem", // 12px
    default: "1rem", // 16px
    md: "1.5rem", // 24px
    lg: "2rem", // 32px
    xl: "3rem", // 48px
  },
  zIndex: {
    popover: 5,
    dialog: 4,
    drawer: 3,
    tooltip: 6,
  },
};

export default theme;
