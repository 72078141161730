import React from "react";
import styled from "styled-components";
import * as RadixTabs from "@radix-ui/react-tabs";

type Props = {
  initialTab?: number;
  headers: string[];
  children: React.ReactNode[];
  activeTab?: number;
  setActiveTab?: (tabIndex: number) => void;
};

const Tabs: React.FC<Props> = ({
  children,
  headers,
  initialTab,
  activeTab,
  setActiveTab,
}) => {
  return (
    <TabsRoot
      defaultValue={initialTab?.toString()}
      value={activeTab?.toString()}
      onValueChange={
        setActiveTab ? (tab) => setActiveTab(parseInt(tab)) : undefined
      }
    >
      <TabsList>
        {headers.map((h, i) => (
          <TabsTrigger key={`${h}-${i}`} value={i.toString()}>
            {h}
          </TabsTrigger>
        ))}
      </TabsList>
      {children.map((c, i) => (
        <TabsContent key={i} value={i.toString()}>
          {c}
        </TabsContent>
      ))}
    </TabsRoot>
  );
};

export default Tabs;

const TabsRoot = styled(RadixTabs.Root)``;
const TabsList = styled(RadixTabs.List)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dividers};
  display: flex;
  gap: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;
const TabsTrigger = styled(RadixTabs.Trigger)`
  margin-bottom: -1px;
  border: none;
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  background-color: transparent;
  font-size: ${({ theme }) => theme.text.tabHeaders.fontSize};
  color: ${({ theme }) => theme.text.tabHeaders.color};
  padding: ${({ theme }) => theme.spacing.xs} 0;
  cursor: pointer;

  &[data-state="active"] {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    color: ${({ theme }) => theme.colors.primary};
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
const TabsContent = styled(RadixTabs.Content)``;
