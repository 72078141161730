import React from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import theme from './theme';
import Layout from './components/Layout';
import Header from './components/Header';

const LazyReactAdmin = React.lazy(() => import('./features/admin/ReactAdmin'));
const LazyLandingPage = React.lazy(() => import('./components/LandingPage'));

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const queryClient = new QueryClient();

const App = () => (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin/*" element={<LazyReactAdmin />} />
            <Route
              path="/*"
              element={
                <Layout>
                  <Header />
                  <LazyLandingPage />
                </Layout>
              }
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </RecoilRoot>
);

export default App;
