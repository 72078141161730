import React from "react";
import styled from "styled-components/macro";
import theme from "../../theme";

type Props = {
  type: keyof typeof theme.headers;
  children: React.ReactNode;
  className?: string;
};

const Header: React.FC<Props> = (props) => (
  <StyledHeader type={props.type} as={props.type} className={props.className}>
    {props.children}
  </StyledHeader>
);


const StyledHeader = styled.h1<{ type: keyof typeof theme.headers }>`
  margin: 0;
  font-family: ${({ theme, type }) => theme.headers[type].fontFamily};
  font-size: ${({ theme, type }) => theme.headers[type].fontSize};
  font-weight: ${({ theme, type }) => theme.headers[type].fontWeight};
  line-height: ${({ theme, type }) => theme.headers[type].lineHeight};
  color: ${({ theme, type }) => theme.headers[type].color};
`;

export default Header;
