import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import * as Select from "@radix-ui/react-select";
import Icon from "./Icon";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";

type Props = {
  languages?: Map<string, string>;
};

const DEFAULT_LANGUAGES = new Map<string, string>([
  ["sv-SE", "Svenska"],
  ["en-UK", "English"],
]);

const LanguageSelector: React.FC<Props> = ({
  languages = DEFAULT_LANGUAGES,
}) => {
  const { i18n } = useTranslation();

  const onSelect = useCallback(
    (e: string) => {
      i18n.changeLanguage(e);
    },
    [i18n]
  );

  return (
    <SelectRoot
      value={i18n.language}
      onValueChange={onSelect}
      defaultValue={i18n.language}
    >
      <SelectTrigger aria-label="Language">
        <SelectValue />
        <SelectIcon>
          <Icon size="sm" icon={faChevronDown} />
        </SelectIcon>
      </SelectTrigger>
      <SelectPortal>
        <SelectContent position="popper" align="end">
          <SelectViewPort>
            {Array.from(languages.entries()).map(([key, display]) => (
              <SelectItem key={key} value={key}>
                <SelectItemText>{display}</SelectItemText>
                <SelectItemIndicator>
                  <Icon icon={faCheck} />
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectViewPort>
        </SelectContent>
      </SelectPortal>
    </SelectRoot>
  );
};

const SelectRoot = styled(Select.Root)``;
const SelectTrigger = styled(Select.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.default}`};
  gap: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  background-color: transparent;
  font-family: ${({ theme }) => theme.typography.fontPrimary};
  font-size: ${({ theme }) => theme.typography.fontSizeSm};
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.greyDark};
  }
`;

const SelectValue = styled(Select.Value)``;
const SelectIcon = styled(Select.Icon)``;
const SelectPortal = styled(Select.Portal)`
  z-index: ${({ theme }) => theme.zIndex.popover};
`;

const SelectContent = styled(Select.Content)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};
`;

const SelectViewPort = styled(Select.Viewport)``;
const SelectItem = styled(Select.Item)`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.typography.fontSizeSm};
  color: ${({ theme }) => theme.colors.white};
  user-select: none;
  cursor: pointer;

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.greyLightest};
  }
`;

const SelectItemText = styled(Select.ItemText)``;
const SelectItemIndicator = styled(Select.ItemIndicator)`
  margin-left: ${({ theme }) => theme.spacing.xs};
`;

export default memo(LanguageSelector);
