import React from "react";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Dialog, Icon } from "../../common/components";
import RiseLogo from "../../common/RiseLogo/RiseLogo";
import Tabs from "../../common/components/Tabs";
import { Grid } from "../../common/containers";
import Button from "../../common/buttons/Button";
import { DialogClose } from "@radix-ui/react-dialog";
import HeaderComponent from "../../common/text/Header";
import IconButton from "../../common/buttons/IconButton";
import { faCircleInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {};

const HelpDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const closedHelpDialogBefore = localStorage.getItem("help") != null;

  return (
    <Dialog
      defaultOpen={!closedHelpDialogBefore}
      trigger={
        <IconButton variant="actionInverted" icon={faCircleInfo}>
          Information
        </IconButton>
      }
      logo={<RiseLogo color="black" size="xl" />}
      onOpenChange={() => localStorage.setItem("help", "y")}
    >
      <Tabs
        initialTab={0}
        headers={[
          t("helpDialog.gettingStarted.tabName"),
          t("helpDialog.disclaimer.tabName"),
          t("helpDialog.contact.tabName"),
        ]}
      >
        <TabContentContainer>
          <HeaderComponent type="h2">
            {t("helpDialog.gettingStarted.title")}
          </HeaderComponent>
          <p>{t("helpDialog.gettingStarted.intro")}</p>
          <ul>
            <li>{t("helpDialog.gettingStarted.introItem1")}</li>
            <li>{t("helpDialog.gettingStarted.introItem2")}</li>
            <li>{t("helpDialog.gettingStarted.introItem3")}</li>
            <li>{t("helpDialog.gettingStarted.introItem4")}</li>
          </ul>
          <p>{t("helpDialog.gettingStarted.purpose")}</p>
          <ol>
            <li>{t("helpDialog.gettingStarted.purposeItem1")}</li>
            <li>{t("helpDialog.gettingStarted.purposeItem2")}</li>
            <li>{t("helpDialog.gettingStarted.purposeItem3")}</li>
          </ol>
          <p>{t("helpDialog.gettingStarted.body")}</p>
          <p>{t("helpDialog.gettingStarted.sign")}</p>
          <DialogClose asChild>
            <StyledButton variant="primary">{t("actions.start")}</StyledButton>
          </DialogClose>
        </TabContentContainer>
        <TabContentContainer>
          <p>{t("helpDialog.disclaimer.paragraph1")}</p>
          <p>{t("helpDialog.disclaimer.paragraph2")}</p>
          <p>{t("helpDialog.disclaimer.paragraph3")}</p>
          <p>{t("helpDialog.disclaimer.paragraph4")}</p>
          <p>{t("helpDialog.disclaimer.paragraph5")}</p>
          <p>{t("helpDialog.disclaimer.paragraph6")}</p>
        </TabContentContainer>
        <TabContentContainer>
          <p>{t("helpDialog.contact.body")}</p>

          <Grid columnsTemplate="1fr 1fr" gap="default">
            <ContactBox>
              <ContactName type="h2">Andreas Bach</ContactName>
              <ContactTitle>{t("helpDialog.contact.director")}</ContactTitle>
              <ContactInformation>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.phone")}
                  </ContactInformationItemLabel>
                  +46 10 516 54 11
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.email")}
                  </ContactInformationItemLabel>
                  andreas.bach@ri.se
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.location")}
                  </ContactInformationItemLabel>
                  Lund
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.unit")}
                  </ContactInformationItemLabel>
                  {t("helpDialog.contact.unitName")} (105702)
                </ContactInformationItem>
              </ContactInformation>
            </ContactBox>

            <ContactBox>
              <ContactName type="h2">Ellinor Forsström</ContactName>
              <ContactTitle>
                {t("helpDialog.contact.projectManager")}
              </ContactTitle>
              <ContactInformation>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.phone")}
                  </ContactInformationItemLabel>
                  +46 73 077 08 11
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.email")}
                  </ContactInformationItemLabel>
                  ellinor.forsstrom@ri.se
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.location")}
                  </ContactInformationItemLabel>
                  Lund
                </ContactInformationItem>
                <ContactInformationItem>
                  <ContactInformationItemLabel>
                    {t("helpDialog.contact.unit")}
                  </ContactInformationItemLabel>
                  {t("helpDialog.contact.unitName")} (105702)
                </ContactInformationItem>
              </ContactInformation>
            </ContactBox>
          </Grid>
        </TabContentContainer>
      </Tabs>
    </Dialog>
  );
};

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const TabContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  line-height: ${({ theme }) => theme.text.primary.lineHeight};
`;

const ContactBox = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.complementary};
  color: ${({ theme }) => theme.colors.white};
`;

const ContactName = styled(HeaderComponent)`
  color: ${({ theme }) => theme.colors.white};
`;

const ContactTitle = styled.p`
  margin-top: ${({ theme }) => theme.spacing.xxs};
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const ContactInformation = styled.ul`
  list-style: none;
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: 0;
  padding-left: 0;
`;

const ContactInformationItem = styled.li`
  display: flex;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xxs};
  }
`;

const ContactInformationItemLabel = styled.span`
  flex: 0 1 5rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export default HelpDialog;
