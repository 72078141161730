import React from "react";
import styled from "styled-components/macro";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Icon } from "../components";
import Button, { ButtonProps } from "./Button";
import { Flex } from "../containers";

type Props = ButtonProps & {
  icon: FontAwesomeIconProps["icon"];
  iconPosition?: "start" | "end";
};

/* 
  TODO: Make sure the html is valid for buttons with icons.
  It's not allowed to use divs inside buttons.
*/

const IconButton: React.FC<Props> = React.forwardRef<any, Props>(
  (
    { icon, iconPosition = "start", children, ...buttonProps },
    forwardedRef
  ) => {
    return (
      <StyledButton {...buttonProps} ref={forwardedRef}>
        <Flex gap="xs" align="center" reverse={iconPosition === "end"}>
          <Icon icon={icon} />
          {children}
        </Flex>
      </StyledButton>
    );
  }
);

const StyledButton = styled(Button)`
  font-size: 0.875rem; // TODO:Should be moved to theme, as small button styling
`;

export default IconButton;
