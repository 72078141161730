import React from "react";
import styled from "styled-components/macro";
import { Grid } from "../../common/containers";

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = (props) => {
  return (
    <StyledGrid columnsTemplate="auto" rowsTemplate="72px auto">
      {props.children}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  height: 100vh;
`;

export default Layout;
