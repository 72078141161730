import React, { memo } from "react";
import styled from "styled-components/macro";
import { Property } from "csstype";
import theme from "../../theme";

type BaseProps = {
  columnsTemplate?: Property.GridTemplateColumns;
  rowsTemplate?: Property.GridTemplateRows;
  gap?: keyof (typeof theme)["spacing"];
};

type Props = BaseProps & {
  children: React.ReactNode;
};

const Grid: React.FC<Props> = ({ children, gap, ...props }) => {
  return (
    <StyledGrid $gap={gap} {...props}>
      {children}
    </StyledGrid>
  );
};

const StyledGrid = styled.div<
  Omit<BaseProps, "gap"> & { $gap?: BaseProps["gap"] }
>`
  display: grid;
  grid-template-columns: ${({ columnsTemplate }) => columnsTemplate ?? ""};
  grid-template-rows: ${({ rowsTemplate }) => rowsTemplate ?? ""};
  gap: ${({ $gap, theme }) => ($gap ? theme.spacing[$gap] : "")};
`;

export default memo(Grid);
