import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LanguageSelector } from "../../common/components";
import RiseLogo from "../../common/RiseLogo/RiseLogo";
import { Flex } from "../../common/containers";
import HelpDialog from "./HelpDialog";
import HeaderComponent from "../../common/text/Header";

type Props = {};

const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <Flex align="center" gap="md">
        <RiseLogo />
        <HeaderComponent type="h1">{t("general.projectName")}</HeaderComponent>
      </Flex>
      <Flex align="center" gap="md">
        <HelpDialog />
        <LanguageSelector />
      </Flex>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.navbarBg};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.md};
`;

export default Header;
